<!--    机构组管理-->
<template>
    <div class="DeptGroup" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form
                @submit.native.prevent
                :inline="true"
                style="text-align: right"
                v-model="form"
                size="small"
                label-width="80px"
            >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="关键字">
                            <el-input placeholder="机构组名称" v-model="form.search" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="机构类型">
                            <el-select placeholder="机构类型" v-model="form.deptType">
                                <el-option label="请选择" value="" />
                                <el-option label="总部" value="1" />
                                <el-option label="配送中心" value="2" />
                                <el-option label="门店" value="3" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button type="primary" @click="handleManualQuery" size="small">查询</el-button>
            <el-button type="primary" @click="handleCreate" size="small">新建</el-button>
            <el-button type="primary" @click="handleExport" size="small">导出</el-button>
            <span style="float: right">
                <el-button size="mini" @click="showCheckTableShowColumnDialog">表头</el-button>
                <el-button size="mini" @click="handlePrint">打印</el-button>
            </span>
        </el-card>
        <check-table-show-column-dialog ref="checkTableShowColumnDialog" @checkTableShowColumn="setShowTableColumns" />
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                ref="table"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="520"
            >
                <el-table-column label="序号" type="index" width="60" align="center" fixed="left" />
                <el-table-column label="编码" width="180" prop="code" v-if="showColumn('code')" />
                <el-table-column label="机构组名称" width="120" prop="name" v-if="showColumn('name')" />
                <el-table-column
                    label="机构类型"
                    width="120"
                    prop="deptType"
                    v-if="showColumn('deptType')"
                    key="deptType"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.deptType | deptType }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="机构数量" width="120" prop="deptCount" v-if="showColumn('deptCount')" />
                <el-table-column label="备注" width="200" prop="remark" v-if="showColumn('remark')" />
                <el-table-column label="创建人" width="120" prop="creator" v-if="showColumn('creator')" />
                <el-table-column label="创建日期" width="140" prop="createTime" v-if="showColumn('createTime')" />

                <el-table-column
                    label="操作"
                    min-width="415"
                    header-align="center"
                    v-if="showColumn('operate')"
                    prop="operate"
                    key="operate"
                >
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="warning"
                            @click="handleEdit(scope.row)"
                            v-if="hasPrivilege('menu.goods.goods.delete')"
                            >编辑
                        </el-button>
                        <el-button
                            size="mini"
                            type="danger"
                            @click="handleDelete(scope.row.code)"
                            v-if="hasPrivilege('menu.goods.goods.delete')"
                            >删除
                        </el-button>
                        <el-button
                            size="mini"
                            type="success"
                            @click="handlePeopleSetting(scope.row)"
                            v-if="hasPrivilege('menu.goods.goods.open')"
                            >人员设置
                        </el-button>
                        <el-button
                            size="mini"
                            type="success"
                            @click="handleDeptSetting(scope.row)"
                            v-if="hasPrivilege('menu.system.dept.show')"
                            >机构设置</el-button
                        >
                        <el-button size="mini" type="success" @click="handleMemberSetting(scope.row)"
                            >会员设置</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                :current-page="form.page"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <el-dialog
            title="会员权限设置"
            :visible.sync="memberSettings.show"
            width="55%"
            top="60px"
            :close-on-click-modal="false"
            :destroyOnClose="true"
        >
            勾选后仅能通过会员卡号使用以下功能(如需使用手机号无须勾选):
            <el-checkbox-group v-model="memberSettings.selected">
                <el-checkbox label="1">消费</el-checkbox>
                <el-checkbox label="2">充值</el-checkbox>
                <el-checkbox label="3">退款</el-checkbox>
            </el-checkbox-group>
            <div slot="footer" class="dialog-footer">
                <el-button @click="memberSettings.show = false" size="small">取 消</el-button>
                <el-button type="primary" @click="handleMemberSettingOk" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import { Message } from 'element-ui';
import CheckTableShowColumn from 'components/mixins/CheckTableShowColumn';
import CheckTableShowColumnDialog from 'components/CheckTableShowColumnDialog';

export default {
    name: 'DeptGroup',
    components: { CheckTableShowColumnDialog },

    mixins: [CheckTableShowColumn],

    data() {
        return {
            form: {
                deptType: '',
                search: '',
                page: 1,
                limit: 20,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/system/deptGroup/page',
                delete: '/system/deptGroup/delete',
                memberSettings: '/system/deptGroup/patchMemberSetting',
            },
            memberSettings: {
                show: false,
                selected: [],
                deptGroupCode: '',
                code: '',
            },
        };
    },
    mounted() {
        this.handleQuery();
    },
    activated() {
        this.executeQueryIfNeedReload(this.handleQuery);
    },
    filters: {
        deptType(deptType) {
            if (typeof deptType == 'undefined') {
                return '';
            }
            if (deptType == '1') {
                return '总部电商';
            }
            if (deptType == '2') {
                return '配送中心';
            }
            if (deptType == '3') {
                return '门店';
            }
        },
    },
    methods: {
        handlePrint() {
            this.printHTML('printMe', this.$route.meta.title);
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        handleManualQuery() {
            this.form.page = 1;
            this.handleQuery();
        },
        handleCreate() {
            Util.nameJump(this, 'menu.system.deptGroup.create', ['系统管理', '机构组', '创建机构组']);
        },
        handleExport() {
            UrlUtils.Export(this, '机构组管理', '/system/deptGroup/export', this.form);
        },
        handleEdit(row) {
            Util.nameJump(this, 'menu.system.deptGroup.edit', ['系统管理', '机构组管理', '编辑机构组'], {
                form: row,
                code: row.code,
            });
        },
        handleDelete(code) {
            UrlUtils.DeleteRemote(this, this.url.delete, code);
        },
        handlePeopleSetting(row) {
            Util.nameJump(this, 'menu.system.deptGroup.people', ['系统管理', '机构组管理', '人员设置'], {
                form: row,
                code: row.code,
            });
        },
        handleDeptSetting(row) {
            Util.nameJump(this, 'menu.system.deptGroup.deptSetting', ['系统管理', '机构组管理', '机构设置'], {
                deptGroupName: row.name,
                deptGroupCode: row.code,
                deptGroupType: row.deptType,
                //是否需要重新加载所有数据flag
                reloadAllFlag: true,
            });
        },
        handleMemberSetting(row) {
            this.memberSettings.show = true;
            this.memberSettings.deptGroupCode = row.code;
            this.memberSettings.code = row.memberSettingParam.code;
            this.memberSettings.selected = row.memberSettingParam.memberCardUseTypes || [];
        },
        handleMemberSettingOk() {
            const param = {
                memberCardUseTypes: this.memberSettings.selected,
                deptGroupCode: this.memberSettings.deptGroupCode,
                code: this.memberSettings.code,
                memberLevelRule: 0,
            };
            UrlUtils.PatchRemote(this, this.url.memberSettings, param, null, () => {
                Message.success({ message: '操作成功' });
                this.memberSettings.show = false;
                this.handleQuery();
            });
        },
        deptList(departmentDos = []) {
            return departmentDos.map((dept) => dept.name);
        },
    },
};
</script>

<style scoped>
.DeptGroup .el-form-item {
    margin-bottom: 0;
}
</style>
